.container {
  @apply mt-8 max-w-7xl;

  @screen md {
    @apply mt-16;
  }
}

.heading {
  @apply text-center mb-6;

  @screen md {
    @apply mb-12;
  }
}
