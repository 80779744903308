@use '@assets/mixins/typography';

.pillContainer {
  @apply flex-1;

  margin-top: 30px;

  &:first-of-type {
    margin-top: 0;
  }

  @screen md {
    margin-top: 40px;
  }

  @screen lg {
    margin-top: 0;
  }
}

.pillContainerSlider {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0 !important;
}

.buttonContainer {
  @apply flex w-fit items-center pl-2 mx-auto mb-5;

  height: 53px;
  background: var(--white);
  border: 2px solid var(--blue-700);
  box-shadow: 4px 4px 0 var(--blue-700);
  border-radius: 1000px;
}

.circle {
  // circle
  width: 37px;
  min-width: 37px;
  height: 37px;
  background: var(--purple);
  border-radius: 1000px;

  // text
  font-size: 22px;
  line-height: 36px;
  color: var(--white);
  font-weight: bold;
}

.buttonText {
  padding-left: 8px;
  padding-right: 15px;
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  align-items: center;
  text-align: center;
}

.imageContainer {
  width: 285px;
  max-width: 100%;
  margin: auto;
}

.imageContainerSlider {
  width: auto;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}

.roundCorners {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.image {
  height: auto;
}

.header {
  font-size: 24.43px;
  padding-bottom: 10px;
}

.textContainerSlider {
  padding: 16px;
}

.bottomText {
  @include typography.text('header-5-book');

  padding-left: 20px;
  padding-right: 20px;
}

.bottomTextSlider {
  color: var(--gray-50);
  padding-left: 0;
  padding-right: 0;
}
