.headline {
  @apply text-cta font-semibold capitalize;
}

.title {
  @apply text-black mb-2;
}

.price {
  @apply mb-4;
}

.imageContent {
  @apply flex mt-4;

  min-height: 290px;

  & picture {
    @apply contents;
  }

  & img {
    object-fit: cover;
  }
}

.imageContentColumn {
  max-width: 390px;
}

.imageSection {
  @media screen and (width >= 768px) {
    padding-left: 6.5em;
  }
}
