@use '@assets/mixins/typography';

.container {
  text-align: center;
  background: var(--lilac);
}

.containerSlider {
  background: var(--white);
}

.eyebrow {
  @apply pb-8;

  @screen md {
    @include typography.text('eyebrow');
  }
}

.header {
  @screen md {
    @include typography.text('header-2');
  }

  padding-bottom: 1em;
}

.headerSlider {
  font-size: 37.9px;
}

.pillsContainer {
  @screen lg {
    display: flex;
  }
}

// Additonal spacing for the slider pagination
.hasPagination {
  @apply pb-12;
}

.swiperPagination {
  @apply absolute z-50 lg:flex justify-center space-x-4 items-center w-full;

  bottom: calc(1.5rem - 6px) !important;
}

.swiperPaginationBullet {
  @apply inline-block bg-outline-primary;

  height: 12px;
  width: 12px;
  background-color: var(--gray-10);
  border-radius: 50%;
}

.activeSwiperPaginationBullet {
  @apply bg-cta;

  height: 12px;
  width: 32px;
  background-color: var(--gray-20);
  border-radius: 100px;
}

.arrowContainer {
  @apply absolute right-0 bottom-2/3 z-50 flex-row justify-between w-full items-center px-2 flex opacity-80;
}

.arrow {
  @apply text-xs md:text-sm border rounded-full flex items-center justify-center;

  width: 44px;
  height: 44px;
  background-color: white !important;
  color: black;
}
