@use '@assets/mixins/typography';

.subheader {
  @screen md {
    @include typography.text('header-2');
  }
}

.reviewIcon {
  @apply flex w-fit items-center mx-auto mb-3 justify-center;

  width: 60px;
  height: 60px;
  align-items: center;
  padding: 14px;
  background: var(--purple);
  border: 2px solid var(--blue-700);
  box-shadow: 4px 4px 0 var(--blue-700);
  border-radius: 100%;
}

.reviewContainer {
  @apply text-left;

  min-height: 213px;
}

.review {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: var(--blue-700);

  @screen md {
    @include typography.text('header-5-book');
  }
}

.headlines {
  @apply mb-7;

  text-transform: uppercase;
  color: var(--purple);
  margin-top: 40px;

  @media (width >= 768px) {
    margin-top: 50px;

    .top {
      margin-top: 0;
    }
  }
}

.top {
  margin-top: 0;
}
